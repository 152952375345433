import React, { useState, useEffect, useMemo, useCallback, useContext, Suspense } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../context';
import { timeAgo, getFormattedChapterTitle, formatNumber, fetchUserRating } from '../utils';
import { useStructuredData, generateNovelStructuredData } from '../hooks';

import { useModal, AppContext } from '../context';
import { LazyImage, FollowButton, NovelPageMobileSkeleton } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faEye, faBook, faBookmark } from '@fortawesome/free-solid-svg-icons';
import './NovelPageMobile.css';
import parse from 'html-react-parser';
import { CommentSection } from '.';


const StarRating = React.lazy(() => import('../components/StarRating'));


export function NovelPageMobile() {
  const { id } = useParams();
  const { user } = useAuth();
  const { fetchNovelDetails, novelDetails } = useContext(AppContext);
  const { openLoginModal } = useModal();

  const [novel, setNovel] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [error, setError] = useState(null);
  const [userRating, setUserRating] = useState({ rating: 0, userHasRated: false });
  const [isLoading, setIsLoading] = useState(true);

  // Generate structured data for the novel and inject it
  const novelStructuredData = novel ? generateNovelStructuredData(novel) : null;
  useStructuredData(novelStructuredData); // Call the hook directly in the function body

  // Fetch novel details
  useEffect(() => {
    const fetchNovel = async () => {
      try {
        setIsLoading(true); // Set loading state
        const novelData = novelDetails[id] ? novelDetails[id] : await fetchNovelDetails(id);
        setNovel(novelData);
      } catch (err) {
        console.error('Error fetching novel details:', err);
        setError('Failed to load novel details. Please try again.');
      } finally {
        setIsLoading(false); // Stop loading after fetch is complete
      }
    };
    if (id) fetchNovel();
  }, [id, novelDetails, fetchNovelDetails]);

  // Fetch user rating if authenticated
  useEffect(() => {
    const fetchUserRatingData = async () => {
      if (user && id) {
        const userRatingData = await fetchUserRating(id, user.id);
        setUserRating(userRatingData);
      }
    };
    fetchUserRatingData();
  }, [user, id]);

  // Handle rating update
  const handleRatingUpdate = useCallback(
    (newRating) => {
      setUserRating((prev) => ({ ...prev, rating: newRating, userHasRated: true }));
    },
    [setUserRating]
  );

  // Memoized chapter sorting
  const sortedChapters = useMemo(() => {
    return novel?.chapters
      ? [...novel.chapters].sort((a, b) => (sortOrder === 'desc' ? b.number - a.number : a.number - b.number))
      : [];
  }, [novel, sortOrder]);

  const toggleSort = useCallback(() => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  }, []);




  // Show loading message while data is being fetched
  if (isLoading) {
    return <NovelPageMobileSkeleton />;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!novel) {
    return <div className="error">Novel not found.</div>;
  }

  return (
    <div className="npm-novel-page">
      <div className="npm-novel-header">
        <div className="npm-book-image">
          <LazyImage src={novel.imageURL} alt={novel.title} />
        </div>
        <div className="npm-book-info">
          <h1 className="npm-book-title">{novel.title}</h1>
          <p className="npm-korean-title">{novel.korean}</p>
          <div className="npm-stats-row">
            <div className="npm-stat-item">
              <FontAwesomeIcon icon={faEye} className="npm-icon" />
              <span>{formatNumber(novel.totalViews)}</span>
            </div>
            <div className="npm-stat-item">
              <FontAwesomeIcon icon={faStar} className="npm-icon" />
              <span>{novel.AverageRating?.toFixed(1)}</span> {/* Display AverageRating from novel details */}
            </div>
            <div className="npm-stat-item">
              <FontAwesomeIcon icon={faBook} className="npm-icon" />
              <span>{novel.chapters.length}</span>
            </div>
            <FollowButton novelId={id} initialFollowersCount={novel.followers} className="npm-follow-button">
              <FontAwesomeIcon icon={faBookmark} className="npm-icon" />
              <span>Follow</span>
            </FollowButton>
          </div>
        </div>
      </div>

      <div className="npm-novel-summary">
        <div className="npm-summary-content">{novel.summary ? parse(novel.summary) : 'No summary available.'}</div>
      </div>

      <div className="npm-author-publisher">
        <p className="npm-author">
          <strong>AUTHOR:</strong> {novel.author}
        </p>
        <p className="npm-publisher">
          <strong>PUBLISHER:</strong> {novel.publisher}
        </p>
      </div>

      <div className="npm-status">{novel.status ? parse(novel.status) : ''}</div>

      <div className="npm-tags-container">
        {novel.tags.map((tag, index) => (
          <Link key={index} to={`/genre/${tag.toLowerCase().trim()}`} className="npm-tag-item">
            {tag.trim()}
          </Link>
        ))}
      </div>

      <div className="npm-ad-placeholder">Placeholder</div>

      <div className="npm-chapters-list">
        <h2 className="npm-chapters-heading">
          CHAPTERS
          <span className="npm-sort-icon" onClick={toggleSort}>
            {sortOrder === 'desc' ? '↓' : '↑'}
          </span>
        </h2>
        <div className="npm-chapters-container">
          <ul>
            {sortedChapters.map((chapter) => (
              <li key={chapter.number} className="npm-chapter-item">
                <Link to={`/novel/${novel.id}/chapter/${chapter.number}`} className="npm-chapter-link">
                  <span>{getFormattedChapterTitle(chapter)}</span>
                </Link>
                <span className="npm-time-ago">{timeAgo(new Date(chapter.uploadedDate))}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="npm-rate-novel-section">
        <Suspense fallback={<div>Loading Rating...</div>}>
          <StarRating
            itemId={novel.id}
            itemType="novel"
            onRate={handleRatingUpdate}
            userHasRated={userRating.userHasRated}
            currentRating={userRating.rating}
            isAuthenticated={!!user}
            userId={user?.id}
            openLoginModal={openLoginModal} // Handle unauthenticated users
          />
        </Suspense>
      </div>
      <div className="comment-section">
        <CommentSection user={user} />
      </div>
    </div>
  );
}

export default React.memo(NovelPageMobile);
