import { useEffect } from 'react';

/**
 * Generates and injects structured data (JSON-LD) into the page head.
 * @param {object} data - The structured data object to inject.
 */
export const useStructuredData = (data) => {
  useEffect(() => {
    if (data) {
      // Create a <script> element to hold the JSON-LD
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(data);
      document.head.appendChild(script);

      // Cleanup: Remove the script when the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [data]);
};

/**
 * Generate structured data (JSON-LD) for a novel.
 * @param {object} novel - The novel data.
 * @returns {object} - The structured data for the novel.
 */
export const generateNovelStructuredData = (novel) => {
  if (!novel) return null;

  return {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": novel.title,
    "korean name": novel.korean,
    "author": {
      "@type": "Person",
      "name": novel.author,
    },
    "url": window.location.href,
    "image": novel.imageURL,
    "description": novel.summary,
    "genre": novel.tags,
    "publisher": {
      "@type": "Organization",
      "name": novel.publisher,
    },
  };
};

export const generateChapterStructuredData = (novel, chapterData) => {
  if (!novel || !chapterData) return null;

  return {
    "@context": "https://schema.org",
    "@type": "Chapter",
    "name": chapterData.title || `Chapter ${chapterData.number}`,  // Fallback to "Chapter X" if title is null
    "position": chapterData.number,
    "partOfBook": {
      "@type": "Book",
      "name": novel.title,
      "korean name": novel.korean,
      "author": {
        "@type": "Person",
        "name": novel.author,
      },
      "publisher": {
        "@type": "Organization",
        "name": novel.publisher,
      },
    },
    "url": window.location.href,
    "description": chapterData.content ? chapterData.content.substring(0, 150) : "", // Short content for description
  };
};
