import React, { useState, useEffect, useMemo, useCallback, useContext, Suspense } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../context';
import { renderStars, timeAgo, getFormattedChapterTitle, fetchUserRating } from '../utils';
import { useStructuredData, generateNovelStructuredData } from '../hooks';


import { useModal, AppContext } from '../context';
import { LazyImage, FollowButton, NovelPageDesktopSkeleton } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBook } from '@fortawesome/free-solid-svg-icons';
import './NovelPageDesktop.css';
import parse from 'html-react-parser';
import { CommentSection } from '.';

const StarRating = React.lazy(() => import('../components/StarRating'));

function NovelPageDesktop() {
  const { id } = useParams();
  const { user } = useAuth();
  const { fetchNovelDetails, novelDetails } = useContext(AppContext);
  const { openLoginModal } = useModal();

  const [novel, setNovel] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [error, setError] = useState(null);
  const [userRating, setUserRating] = useState({ rating: 0, userHasRated: false });
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  // Generate structured data for the novel (even if novel is null)
  const novelStructuredData = useMemo(() => {
    return novel ? generateNovelStructuredData(novel) : null;
  }, [novel]);
  
  useStructuredData(novelStructuredData);  // Move this outside the conditional check


  // Fetch novel details
  useEffect(() => {
    const fetchNovel = async () => {
      try {
        setIsLoading(true); // Set loading to true before starting
        const novelData = novelDetails[id] ? novelDetails[id] : await fetchNovelDetails(id);
        setNovel(novelData);
      } catch (err) {
        console.error("Error fetching novel details:", err);
        setError("Failed to load novel details. Please try again.");
      } finally {
        setIsLoading(false); // Set loading to false after fetching is complete
      }
    };
    if (id) fetchNovel();
  }, [id, novelDetails, fetchNovelDetails]);

  // Fetch user rating if authenticated
  useEffect(() => {
    const fetchUserRatingData = async () => {
      if (user && id) {
        const userRatingData = await fetchUserRating(id, user.id);
        setUserRating(userRatingData);
      }
    };
    fetchUserRatingData();
  }, [user, id]);

  // Handle rating update
  const handleRatingUpdate = useCallback(
    (newRating) => {
      setUserRating((prev) => ({ ...prev, rating: newRating, userHasRated: true }));
    },
    [setUserRating]
  );

  const sortedChapters = useMemo(() => {
    return novel?.chapters
      ? [...novel.chapters].sort((a, b) => (sortOrder === 'desc' ? b.number - a.number : a.number - b.number))
      : [];
  }, [novel, sortOrder]);

  const toggleSort = useCallback(() => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  }, []);



  // Use the loading state to conditionally display the skeleton while the main content is hidden
  return (
    <div className="novel-page">
      {/* Overlay skeleton screen that fades out */}
      <div className={`novel-skeleton-overlay ${isLoading ? 'visible' : 'hidden'}`}>
        <NovelPageDesktopSkeleton />
      </div>

      {/* Main content */}
      <div className={`novel-content ${isLoading ? 'hidden' : 'visible'}`}>
        {error && <div className="error">{error}</div>}
        {!novel && !error && <div className="error">Novel not found.</div>}

        {novel && (
          <>
            <div className="novel-header">
              <div className="book-image">
                <LazyImage src={novel.imageURL} alt={novel.title} />
              </div>
              <div className="book-info">
                <h1>{novel.title}</h1>
                <p className="korean-title">{novel.korean}</p>
                <div className="stats">
                  <div className="total-views">
                    <FontAwesomeIcon icon={faEye} className="icon" />
                    <span>{novel.totalViews} views</span>
                  </div>
                  <div className="total-chapters">
                    <FontAwesomeIcon icon={faBook} className="icon" />
                    <span>{novel.chapters.length} {novel.chapters.length === 1 ? 'chapter' : 'chapters'}</span>
                  </div>
                </div>
                <div className="rating-result">{renderStars(novel.AverageRating)}</div>
                <FollowButton novelId={id} initialFollowersCount={novel.followers} className="ndp-follow-button" />
                <div className="ad-placeholder">Placeholder</div>
              </div>
            </div>

            <div className="novel-summary">
              <h2>SUMMARY:</h2>
              <div className="summary-content">{novel.summary ? parse(novel.summary) : 'No summary available.'}</div>
              <div className="author-studio">
                <p className="author"><strong>AUTHOR:</strong> {novel.author}</p>
                <p className="studio"><strong>PUBLISHER:</strong> {novel.publisher}</p>
              </div>
              <div className="status"> {novel.status ? parse(novel.status) : ''}</div>
              <div className="tags-container">
                {novel.tags.map((tag, index) => (
                  <Link key={index} to={`/genre/${tag.toLowerCase().trim()}`} className="tag-item">
                    {tag.trim()}
                  </Link>
                ))}
              </div>
            </div>

            <div className="chapters-list">
              <h2>
                CHAPTERS
                <span className="sort-icon" onClick={toggleSort}>
                  {sortOrder === 'desc' ? '↓' : '↑'}
                </span>
              </h2>
              <div className="chapters-container">
                <ul>
                  {sortedChapters.map((chapter) => (
                    <li key={chapter.number}>
                      <Link to={`/novel/${novel.id}/chapter/${chapter.number}`}>
                        <span>{getFormattedChapterTitle(chapter)}</span>
                      </Link>
                      <span className="time-ago">{timeAgo(new Date(chapter.uploadedDate))}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="rate-novel-section">
              <Suspense fallback={<div>Loading Rating...</div>}>
                <StarRating
                  itemId={novel.id}
                  itemType="novel"
                  isAuthenticated={!!user}
                  userId={user?.id}
                  openLoginModal={openLoginModal}
                  onRatingUpdate={handleRatingUpdate} // Updated with the new handler
                  userHasRated={userRating.userHasRated} // Whether the user has rated this novel
                  currentRating={userRating.rating} // User's current rating for this novel
                />
              </Suspense>
            </div>
            <div className="comment-section">
              <CommentSection user={user} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(NovelPageDesktop);
