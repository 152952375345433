import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../services';
import { useAuth } from '../context';
import { useModal } from '../context';
import '../pages/SignUpPage.css';
import { FaDiscord, FaEye, FaEyeSlash } from "react-icons/fa";

export function LoginPage({ onClose, contextMessage }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const modalRef = useRef(null);
  const { openSignUpModal } = useModal();
  const { login, user } = useAuth();  // Added user to get current logged-in user
  
  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Log the user in via your authentication system (e.g., Supabase)
      await login(email, password, rememberMe);
      onClose();

      // After login, fetch the SSO token for Hyvor Talk from the backend
      const response = await fetch('/api/hyvor-talk-sso', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user }),  // Send user data to generate the SSO token
      });
      const ssoData = await response.json();

      // Store the SSO token for use in the comment section
      localStorage.setItem('ssoToken', JSON.stringify(ssoData));

      // Redirect to the return URL, or stay on the current page
      const returnUrl = localStorage.getItem('returnUrl');
      if (returnUrl) {
        window.history.replaceState(null, '', returnUrl);  // Instead of redirecting, just update the URL without reloading
        localStorage.removeItem('returnUrl');
      } else {
        console.log('No return URL found, staying on the same page.');
      }

    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [email, password, rememberMe, login, user, onClose]);

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setError(null);
      onClose();
      event.preventDefault();
    }
  }, [onClose]);

  const handleDiscordLogin = async () => {
    try {
      // Save current URL before OAuth redirect
      localStorage.setItem('returnUrl', window.location.href);

      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'discord',
        options: {
          redirectTo: window.location.href,  // Redirect to the current page after OAuth
        },
      });
      
      if (error) {
        console.error('Error with Discord login:', error.message);
      }
    } catch (err) {
      console.error('Error during Discord login:', err.message);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
  
    if (code) {
      console.log('Authorization code received:', code);
  
      (async () => {
        try {
          const { session, error } = await supabase.auth.exchangeCodeForSession(code);
          
          if (error) {
            console.error('Error exchanging code for session:', error.message);
            setError('Failed to complete login. Please try again.');
          } else {
            console.log('Session created:', session);
          }
        } catch (err) {
          console.error('Error during token exchange:', err.message);
          setError('An unexpected error occurred. Please try again.');
        }
      })();
    } else {
      console.error('Authorization code missing from the URL.');
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="modal" role="dialog" aria-labelledby="login-title" aria-modal="true">
      <div className="modal-content" ref={modalRef}>
        <h2 id="login-title">Login to your account</h2>
        {contextMessage && <p className="context-message">{contextMessage}</p>}
        
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="modal-input"
            disabled={loading}
            aria-label="Email"
          />
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="modal-input password-input"
              disabled={loading}
              aria-label="Password"
            />
            <button 
              type="button" 
              onClick={togglePasswordVisibility} 
              className="password-toggle-btn"
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="login-options">
            <label className="remember-me-label">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                disabled={loading}
                aria-label="Remember me"
              />
              <span>Remember me</span>
            </label>
            <Link to="/forgot-password" className="forgot-password-link">Forgot password?</Link>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={loading} className="modal-button login-button" aria-busy={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        
        <div className="login-divider">
          <span>OR</span>
        </div>
        
        <button onClick={handleDiscordLogin} className="modal-button discord-login-button">
          <FaDiscord className="discord-icon" />
          Login with Discord
        </button>
        
        <p className="register-prompt">
          Don't have an account? <button className="switch-modal-button" onClick={() => { onClose(); openSignUpModal(); }}>Create a new account</button>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;