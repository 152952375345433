import React, { useState, useCallback, useMemo } from 'react';
import { Star } from 'lucide-react';
import './StarRating.css';
import { updateRating } from '../utils/starRatingUtils';

const StarRating = ({
  itemId,
  itemType,
  isAuthenticated,
  userId,
  openLoginModal,
  onRatingUpdate, // Callback function to notify the parent of rating updates
  userHasRated,   // Whether the user has already rated this item
  currentRating,  // User's current rating for the item
}) => {
  const [hover, setHover] = useState(0);
  const [thankYouMessage, setThankYouMessage] = useState(false);

  // Handle rating click event
  const handleClick = useCallback(
    async (ratingValue) => {
      if (!isAuthenticated) {
        openLoginModal(); // Trigger login modal if user is not authenticated
        return;
      }

      try {
        const success = await updateRating(userId, itemId, ratingValue);
        if (success) {
          setThankYouMessage(true);
          setTimeout(() => setThankYouMessage(false), 3000);

          // Notify the parent component of the updated rating
          onRatingUpdate(ratingValue);
        }
      } catch (error) {
        console.error('Error updating rating:', error);
      }
    },
    [isAuthenticated, userId, itemId, openLoginModal, onRatingUpdate]
  );

  // Render the stars based on hover and current rating
  const renderedStars = useMemo(() => {
    return [...Array(5)].map((_, index) => {
      const ratingValue = index + 1;
      return (
        <label key={index}>
          <input
            type="radio"
            name="rating"
            value={ratingValue}
            onClick={() => handleClick(ratingValue)}
          />
          <Star
            className={`star ${ratingValue <= (hover || currentRating) ? 'filled' : ''}`}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(0)}
            size={25}
            strokeWidth={1}
          />
        </label>
      );
    });
  }, [hover, currentRating, handleClick]);

  return (
    <div className="star-rating">
      {/* Display the text indicating the rating value */}
      {currentRating > 0 && (
        <p className="rating-value-text">
          {`Rated: ${currentRating.toFixed(1)} stars`}
        </p>
      )}

      {/* Display rate message */}
      {!isAuthenticated ? (
        <p className="rate-novel-text">Rate this Novel</p>
      ) : (
        !userHasRated && <p className="rate-novel-text">Rate this Novel</p>
      )}

      {/* Display the user's rating if authenticated and has rated */}
      {isAuthenticated && userHasRated && !thankYouMessage && (
        <p className="rated-message">
          You've rated this {itemType}: {currentRating.toFixed(1)} stars
        </p>
      )}

      {/* Thank you message after a successful rating */}
      {thankYouMessage && (
        <p className="thank-you-message">Thank you! Your rating has been recorded.</p>
      )}

      {/* Render the star elements */}
      <div className="stars">{renderedStars}</div>
    </div>
  );
};

export default React.memo(StarRating);
