import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { Navbar, ModalManager } from './components';
import { LoginPage, SearchResults, HomePage, NovelPage, ChapterPage, AccountPage, Bookmarks, TagResults, AboutPage, AdminPage } from './pages';
import { ModalProvider, AppProvider, AuthProvider, useAuth } from './context';
import { AboutPageProvider, AccountProvider } from './context';  // Import AccountProvider
import { AdminProvider } from './context/AdminContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AccountProvider>  {/* Wrap AccountProvider around the entire app */}
          <AppProvider>
            <ModalProvider>
              <div className="App">
                <Navbar />
                <ModalManager />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/account" element={<ProtectedRoute component={AccountPage} />} />
                  <Route path="/bookmarks" element={<ProtectedRoute component={Bookmarks} />} />
                  <Route path="/novel/:id" element={<NovelPage />} />
                  <Route path="/novel/:id/chapter/:chapterNumber" element={<ChapterPage />} />
                  <Route path="/search" element={<SearchResults />} />
                  <Route path="/genre/:tag" element={<TagResults />} />

                  {/* Wrap AboutPage with AboutPageProvider */}
                  <Route
                    path="/about"
                    element={
                      <AboutPageProvider>
                        <AboutPage />
                      </AboutPageProvider>
                    }
                  />

                  {/* Wrap AdminPage with ProtectedAdminRoute */}
                  <Route
                    path="/admin"
                    element={<ProtectedAdminRoute component={AdminPage} />}
                  />
                </Routes>
              </div>
            </ModalProvider>
          </AppProvider>
        </AccountProvider>
      </AuthProvider>
    </Router>
  );
}

function ProtectedRoute({ component: Component }) {
  const { user, loading, isAdmin } = useAuth();

  if (loading) return <div>Loading...</div>;

  return user || isAdmin ? <Component /> : <Navigate to="/login" />;
}

function ProtectedAdminRoute({ component: Component }) {
  const { isAdmin, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return isAdmin ? (
    <AdminProvider>
      <Component />
    </AdminProvider>
  ) : (
    <Navigate to="/" />
  );
}

export default App;
