import React, { useContext } from 'react';
import { AboutPageContext } from '../context/AboutPageContext'; // Adjust the import path if needed
import { cleanHTMLContent } from '../utils/cleanHTMLContent'; // Ensure this function properly sanitizes HTML content
import './AboutPage.css'; // Ensure this CSS file contains the styles for your HTML content

const AboutPage = () => {
  const { aboutContent, loading, error } = useContext(AboutPageContext);

  if (loading) {
    return <div className="about-page loading">Loading...</div>;
  }

  if (error) {
    return <div className="about-page error">{error}</div>;
  }

  if (!aboutContent || !aboutContent.title || !aboutContent.content) {
    return <div className="about-page error">Content not available</div>;
  }

  return (
    <div className="about-page">
      <h1 className="about-title">{aboutContent.title}</h1>
      <div
        className="about-content"
        dangerouslySetInnerHTML={{ __html: cleanHTMLContent(aboutContent.content) }}
      />
    </div>
  );
};


export default AboutPage;