import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { renderStars, timeAgo, getFormattedChapterTitleForHomePage } from '../utils';
import { AppContext } from '../context';
import { IoEye } from "react-icons/io5";
import { FaListAlt } from "react-icons/fa";
import { useAnnouncements } from '../hooks';
import { HomePageSkeleton } from '../components';

import './HomePage.css';

export function HomePage() {
  const { latestUpdates, fetchLatestUpdates, novels, fetchNovels } = useContext(AppContext);
  const [genres, setGenres] = useState([]);
  const [error, setError] = useState(null);
  const [loadingNovels, setLoadingNovels] = useState(true);
  const [loadingUpdates, setLoadingUpdates] = useState(true);
  const { jsonContent, announcementTitle, loadingJson } = useAnnouncements();

  const fetchData = useCallback(() => {
    const allTags = novels.flatMap((novel) => {
      try {
        return Array.isArray(novel.tags) ? novel.tags.map((tag) => tag.trim()) : [];
      } catch (e) {
        console.error('Error parsing tags for novel:', novel.id, e);
        return [];
      }
    });
    const uniqueTags = [...new Set(allTags)];
    setGenres(uniqueTags);
  }, [novels]);

  useEffect(() => {
    const loadUpdates = async () => {
      try {
        setLoadingUpdates(true);
        await fetchLatestUpdates();
      } catch (err) {
        setError('Failed to fetch updates.');
      } finally {
        setLoadingUpdates(false);
      }
    };

    const loadNovels = async () => {
      try {
        setLoadingNovels(true);
        await fetchNovels();
      } catch (err) {
        setError('Failed to fetch novels.');
      } finally {
        setLoadingNovels(false);
      }
    };

    loadUpdates();
    loadNovels();
  }, [fetchLatestUpdates, fetchNovels]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loadingNovels || loadingUpdates) {
    return (
      <div className="loading-container">
        <HomePageSkeleton />
      </div>
    );
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="home-page">
      <div className="announcement">
        {announcementTitle && <h3 className="announcement-title">{announcementTitle}</h3>}
        {loadingJson ? (
          <p className="announcement-text">Loading announcement...</p>
        ) : (
          <p className="announcement-text">{jsonContent || "No announcements at this time."}</p>
        )}
      </div>
      <section className="latest-updates">
        <h2 className="section-header">LATEST UPDATES</h2>
        <div className="updates-grid">
          {latestUpdates.length > 0 ? (
            latestUpdates.map((novel) => (
              <div key={novel.id} className="update-card">
                <Link to={`/novel/${novel.id}`} className="book-cover">
                  <div
                    className="book-image"
                    style={{
                      backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none',
                      backgroundColor: 'var(--light-peach)'
                    }}>
                  </div>
                </Link>
                <div className="update-info">
                  <h3>{novel.title}</h3>
                  {novel.chapters && novel.chapters.map((chapter) => (
                    <Link
                      key={chapter.id}
                      to={`/novel/${novel.id}/chapter/${chapter.number}`}
                      className="chapter-link"
                    >
                      <span className="chapter-number">{getFormattedChapterTitleForHomePage(chapter)}</span>
                      <span className="upload-time">{timeAgo(chapter.uploadedDate)}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No recent updates</p>
          )}
        </div>
      </section>
      <section className="novels">
        <div className="section-header-wrapper">
          <h2>NOVELS</h2>
          <span className="novel-in-progress">translation in progress</span>
        </div>
        <div className="novels-grid">
          {novels.map((novel) => (
            <div key={novel.id} className="novel-card">
              <Link to={`/novel/${novel.id}`} className="book-cover">
                <div
                  className="book-image"
                  style={{
                    backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none',
                    backgroundColor: 'var(--light-peach)'
                  }}
                ></div>
              </Link>
              <div className="novel-info">
                <h3 className="book-title-link">
                  <Link to={`/novel/${novel.id}`} className="book-title-link">
                    {novel.title}
                  </Link>
                </h3>
                <div className="rating-container">
                  {renderStars(novel.AverageRating)}
                </div>
                <div className="novel-stats">
                  <div className="novel-stat">
                    <IoEye /> <span>{novel.totalViews || 0}</span>
                  </div>
                  <div className="novel-stat">
                    <FaListAlt /> <span>{novel.totalChapters || 0}</span>
                  </div>
                </div>
                <div className="tags">
                  {Array.isArray(novel.tags) && novel.tags.map((tag, index) => (
                    <Link key={index} to={`/genre/${tag.toLowerCase().trim()}`} className="tag-item">
                      {tag.trim()}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="tags-footer">
        <h2 className="section-header small-header">Genre</h2>
        <div className="tags-list">
          {genres.map((genre, index) => (
            <div key={index} className="tag-item">
              <Link to={`/genre/${genre.toLowerCase()}`}>
                {genre}
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default HomePage;