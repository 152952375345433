import React, { createContext, useEffect, useState, useCallback, useContext } from 'react';
import { supabase } from '../services';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  // Single admin user ID (from environment variable)
  const ADMIN_USER_ID = process.env.REACT_APP_ADMIN_USER_ID;



  // Add this to your AuthContext.js
  const login = async (email, password, rememberMe) => {
    setLoading(true);
    try {
      const { data: { user }, error: loginError } = await supabase.auth.signInWithPassword({ email, password });
      if (loginError) throw new Error('Invalid email or password. Please try again.');

      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('username, avatar_url')
        .eq('user_id', user.id)
        .single();
      if (profileError) throw new Error('Failed to fetch user profile information. Please try again later.');

      setUser(user);  // Set user in context
      setProfile(profileData);  // Set profile data in context

      if (rememberMe) {
        localStorage.setItem('user', JSON.stringify({
          id: user.id,
          email: user.email,
          username: profileData.username,
          profilePic: profileData.avatar_url,
        }));
      }
    } catch (err) {
      throw err;  // Propagate the error to the caller
    } finally {
      setLoading(false);
    }
  };


  // Add this to your AuthContext.js
  const signup = async (email, password, username) => {
    setLoading(true);
    try {
      const { data: { user }, error: signUpError } = await supabase.auth.signUp({ email, password });
      if (signUpError) throw signUpError;
      if (!user) throw new Error('Sign-up failed. Please try again.');

      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .insert([{ user_id: user.id, email: user.email, username }])
        .select('username, avatar_url')
        .single();
      if (profileError) throw profileError;

      setUser(user);  // Set user in context
      setProfile(profileData);  // Set profile data in context

      localStorage.setItem('user', JSON.stringify({
        id: user.id,
        email: user.email,
        username: profileData.username,
        profilePic: profileData.avatar_url,
      }));
    } catch (err) {
      throw err;  // Propagate the error to the caller
    } finally {
      setLoading(false);
    }
  };


  

  

  // Fetch user profile from Supabase
  const fetchUserProfile = useCallback(async (userId) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('username, email, avatar_url')
        .eq('user_id', userId)
        .single();
      if (error) throw new Error(error.message);

      if (data) {
        setProfile(data);
      }
    } catch (err) {
      console.error('Error fetching user profile:', err.message);
    }
  }, []);

  // Handle session changes (sign in, sign out)
  const handleSessionChange = useCallback(
    async (session) => {
      if (session) {
        setUser(session.user);
        await fetchUserProfile(session.user.id);
        setIsAdmin(session.user.id === ADMIN_USER_ID);
      } else {
        setUser(null);
        setProfile(null);
        setIsAdmin(false);
      }
      setLoading(false);  // Ensure loading is set to false after handling session
    },
    [fetchUserProfile, ADMIN_USER_ID]
  );

  // Initialize session and listen for auth state changes
  useEffect(() => {
    const checkSession = async () => {
      try {
        setLoading(true);
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw new Error(error.message);

        handleSessionChange(session);
      } catch (err) {
        console.error('Error fetching session:', err.message);
        setLoading(false);
      }
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      console.log(`Auth event: ${event}`);
      handleSessionChange(session);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [handleSessionChange]);

  const logout = async () => {
    try {
      // Sign out the user from Supabase
      await supabase.auth.signOut();
  
      // Clear the user-related data in the local state
      setUser(null);
      setProfile(null);
      setIsAdmin(false);
  
      // Clear user-related data from localStorage
      localStorage.removeItem('user');  // Assuming you store user info under 'user'
      localStorage.removeItem('hyvorSsoToken');  // Remove the Hyvor Talk SSO token if you store it
  
      // Optionally, clear everything from localStorage if needed
      // localStorage.clear();
  
      // Navigate the user to the homepage or login page
      navigate('/');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };
  

  const value = {
    user,
    profile,
    isAdmin,
    loading,
    logout,
    login,
    signup,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
