import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../services';
import './ChapterPage.css';
import { useAuth, useModal, AppContext } from '../context';
import { 
  cleanHTMLContent, 
  buildChapterIndex, 
  getNavigationButtons, 
  fetchUserRating,
  getFormattedChapterTitle,
} from '../utils';
import { useStructuredData, generateChapterStructuredData } from '../hooks';


import { ScrollArrows, ChapterContent, ProgressBar, SkeletonScreen, LoadingSpinner } from '../components';
import { CommentSection } from '.';

const StarRating = lazy(() => import('../components/StarRating'));

export function ChapterPage() {
  const { id, chapterNumber } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { openLoginModal } = useModal();
  const { novelDetails, fetchNovelDetails } = useContext(AppContext);
  const chapterContentRef = useRef(null);

  const [novel, setNovel] = useState(() => novelDetails[id] || null);
  const [chapterData, setChapterData] = useState(null);
  const [allChapters, setAllChapters] = useState([]);
  const [chapterIndex, setChapterIndex] = useState([]);
  const [error, setError] = useState(null);
  const [userRating, setUserRating] = useState({ rating: 0, userHasRated: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [preloadedChapters, setPreloadedChapters] = useState({});



  // Always generate structured data, passing `null` if novel or chapterData is not yet available
  const chapterStructuredData = novel && chapterData ? generateChapterStructuredData(novel, chapterData) : null;

  // Always call the hook
  useStructuredData(chapterStructuredData);


  const fetchChapterContent = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch chapter content');

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let content = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        content += decoder.decode(value, { stream: true });
      }

      return cleanHTMLContent(content);
    } catch (error) {
      console.error('Failed to fetch chapter content:', error.message);
      throw error;
    }
  }, []);

  const preloadAdjacentChapters = useCallback(async (currentChapterNumber) => {
    const adjacentChapters = [currentChapterNumber - 1, currentChapterNumber + 1];
    
    for (const chapterNum of adjacentChapters) {
      if (chapterNum > 0 && chapterNum <= allChapters.length && !preloadedChapters[chapterNum]) {
        const chapterToPreload = allChapters.find(ch => ch.number === chapterNum);
        if (chapterToPreload) {
          const content = await fetchChapterContent(chapterToPreload.contentURL);
          setPreloadedChapters(prev => ({...prev, [chapterNum]: content}));
        }
      }
    }
  }, [allChapters, fetchChapterContent, preloadedChapters]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const novelData = novelDetails[id] ? novelDetails[id] : await fetchNovelDetails(id);
        setNovel(novelData);

        const [chaptersResponse, chapterResponse] = await Promise.all([
          supabase.from('chapters').select('id, number, title').eq('novelId', id).order('number'),
          supabase.from('chapters').select('*').eq('novelId', id).eq('number', chapterNumber).single(),
        ]);

        if (chaptersResponse.error) throw chaptersResponse.error;
        if (chapterResponse.error) throw chapterResponse.error;

        const content = await fetchChapterContent(chapterResponse.data.contentURL);

        setAllChapters(chaptersResponse.data);
        setChapterIndex(buildChapterIndex(chaptersResponse.data));
        setChapterData({
          ...chapterResponse.data,
          content
        });

        if (user) {
          const userRatingData = await fetchUserRating(id, user.id);
          setUserRating(userRatingData);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load chapter content. Please try again later.');
      } finally {
        setIsLoading(false);
        setIsFirstLoad(false);
      }
    };

    if (id && chapterNumber) {
      fetchData();
    }
  }, [id, chapterNumber, novelDetails, fetchNovelDetails, user, fetchChapterContent]);

  useEffect(() => {
    const fetchData = async () => {
      // Avoid refetching if novel and chapter data already exist
      if (novel && chapterData) {
        setIsLoading(false);
        return;
      }
  
      setIsLoading(true);
      try {
        const novelData = novelDetails[id] ? novelDetails[id] : await fetchNovelDetails(id);
        setNovel(novelData);
  
        const [chaptersResponse, chapterResponse] = await Promise.all([
          supabase.from('chapters').select('id, number, title').eq('novelId', id).order('number'),
          supabase.from('chapters').select('*').eq('novelId', id).eq('number', chapterNumber).single(),
        ]);
  
        if (chaptersResponse.error) throw chaptersResponse.error;
        if (chapterResponse.error) throw chapterResponse.error;
  
        const content = await fetchChapterContent(chapterResponse.data.contentURL);
  
        setAllChapters(chaptersResponse.data);
        setChapterIndex(buildChapterIndex(chaptersResponse.data));
        setChapterData({
          ...chapterResponse.data,
          content
        });
  
        if (user) {
          const userRatingData = await fetchUserRating(id, user.id);
          setUserRating(userRatingData);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load chapter content. Please try again later.');
      } finally {
        setIsLoading(false);
        setIsFirstLoad(false);
      }
    };
  
    // Only fetch if necessary
    if (id && chapterNumber && (!novel || !chapterData)) {
      fetchData();
    }
  }, [id, chapterNumber, novel, chapterData, novelDetails, fetchNovelDetails, user, fetchChapterContent]);
  

  useEffect(() => {
    if (chapterData) {
      preloadAdjacentChapters(chapterData.number);
    }
  }, [chapterData, preloadAdjacentChapters]);


  const handleChapterChange = useCallback(
    async (selectedChapterNumber) => {
      setIsNavigating(true);
      setLoadingProgress(0);
      
      const intervalId = setInterval(() => {
        setLoadingProgress(prev => Math.min(prev + 10, 90));
      }, 500);

      navigate(`/novel/${id}/chapter/${selectedChapterNumber}`);

      if (preloadedChapters[selectedChapterNumber]) {
        setChapterData(prevData => ({
          ...prevData,
          number: selectedChapterNumber,
          content: preloadedChapters[selectedChapterNumber]
        }));
        setIsNavigating(false);
        setLoadingProgress(100);
        clearInterval(intervalId);
      } else {
        try {
          const chapterResponse = await supabase
            .from('chapters')
            .select('*')
            .eq('novelId', id)
            .eq('number', selectedChapterNumber)
            .single();

          if (chapterResponse.error) throw chapterResponse.error;

          const content = await fetchChapterContent(chapterResponse.data.contentURL);
          
          setChapterData({
            ...chapterResponse.data,
            content
          });
        } catch (error) {
          console.error('Failed to fetch chapter:', error);
          setError('Failed to load chapter. Please try again.');
        } finally {
          setIsNavigating(false);
          setLoadingProgress(100);
          clearInterval(intervalId);
        }
      }
    },
    [id, navigate, preloadedChapters, fetchChapterContent]
  );

  const topNavigationButtons = useMemo(() => {
    if (!chapterData || !chapterIndex.length) return [];
    return getNavigationButtons(chapterData, chapterIndex, true);
  }, [chapterData, chapterIndex]);

  const bottomNavigationButtons = useMemo(() => {
    if (!chapterData || !chapterIndex.length) return [];
    return getNavigationButtons(chapterData, chapterIndex, false);
  }, [chapterData, chapterIndex]);

  if (isFirstLoad) {
    return <LoadingSpinner />;
  }

  if (!novel || !chapterData) {
    return <SkeletonScreen />;
  }

  return (
    <div className="chapter-page">
      {isNavigating && <ProgressBar progress={loadingProgress} />}
      <ScrollArrows contentRef={chapterContentRef} />


      <header className="chapter-header">
        <Link to={`/novel/${id}`} className="book-title-link">
          <h1 className="book-title">{novel.title}</h1>
        </Link>
        <div className="chapter-nav">
          <div className="chapter-select">
            <select
              className="chapter-dropdown"
              value={chapterData.number}
              onChange={(e) => handleChapterChange(e.target.value)}
              aria-label="Select Chapter"
            >
              {allChapters.map((ch) => (
                <option key={ch.number} value={ch.number} title={ch.title}>
                  {getFormattedChapterTitle(ch)}
                </option>
              ))}
            </select>
          </div>
          
          {/* Move top navigation buttons inside chapter-nav */}
          <div className="top-nav-buttons">
            {topNavigationButtons.map((button) => (
              <Link
                key={button.type}
                to={`/novel/${id}/chapter/${button.type === 'prev' ? chapterData.number - 1 : chapterData.number + 1}`}
                className={`nav-button ${button.type}`}
              >
                {button.icon}
                <span>{button.label}</span>
              </Link>
            ))}
          </div>
        </div>
      </header>




      <main className="chapter-content" ref={chapterContentRef}>
        {isNavigating ? (
          <SkeletonScreen />
        ) : isLoading ? (
          <LoadingSpinner />
        ) : (
          <ChapterContent
            chapterData={chapterData}
            isLoading={isLoading}
            isFirstLoad={isFirstLoad}
            error={error}
          />
        )}
      </main>

      <div className="bottom-nav">
        <div className="chapter-select">
          <select
            className="chapter-dropdown"
            value={chapterData.number}
            onChange={(e) => handleChapterChange(e.target.value)}
            aria-label="Select Chapter"
          >
            {allChapters.map((ch) => (
              <option key={ch.number} value={ch.number}>
                {getFormattedChapterTitle(ch)}
              </option>
            ))}
          </select>
        </div>
        {bottomNavigationButtons.map((button) => (
          <Link
          key={button.type}
          to={`/novel/${id}/chapter/${button.type === 'prev' ? chapterData.number - 1 : chapterData.number + 1}`}
          className={`nav-button ${button.type}`}
          >
          {button.icon}
          <span>{button.label}</span>
          </Link>
        ))}
      </div>

      <div className="rating-section">
        <Suspense fallback={<div>Loading Rating...</div>}>
          <StarRating
            itemId={novel.id}
            itemType="novel"
            isAuthenticated={!!user}
            userId={user?.id}
            openLoginModal={openLoginModal}
            userHasRated={userRating.userHasRated}
            currentRating={userRating.rating}
          />
        </Suspense>
      </div>
      <div className="comment-section">
        <CommentSection user={user} />
      </div>
    </div>
  );
}

export default React.memo(ChapterPage);
