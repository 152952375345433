import { useState, useEffect } from 'react';
import { subscribeToAnnouncements, supabase } from '../services';

// Helper function to check if a string is a valid URL
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const useAnnouncements = () => {
  const [jsonContent, setJsonContent] = useState(''); // State to store JSON content from URL
  const [announcementTitle, setAnnouncementTitle] = useState(''); // State to store the title
  const [loadingJson, setLoadingJson] = useState(false); // State to handle loading of JSON content
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncementsDirectly = async () => {
      try {
        const { data, error } = await supabase
          .from('announcement') // Correct table name: 'announcement'
          .select('*')
          .order('created_at', { ascending: false });

        if (error) {
          console.error('Error fetching announcements:', error);
          setError('Failed to load announcements.');
          return;
        }

        console.log('Fetched announcements:', data);
        if (data.length > 0) {
          const newAnnouncement = data[0]; // Get the latest announcement
          setAnnouncementTitle(newAnnouncement.title || ""); // Set title or empty if null

          if (isValidUrl(newAnnouncement.message)) {
            setLoadingJson(true);
            fetch(newAnnouncement.message)
              .then((response) => response.json())
              .then((jsonData) => setJsonContent(jsonData.content)) // Assuming 'content' key exists in JSON file
              .catch((error) => {
                console.error('Error fetching JSON content:', error);
                setJsonContent('Failed to load content.');
              })
              .finally(() => setLoadingJson(false));
          } else {
            setJsonContent(newAnnouncement.message); // If not a URL, directly use the message content
          }
        }
      } catch (error) {
        console.error('Error fetching announcements directly:', error);
        setError('Failed to load announcements.');
      }
    };

    fetchAnnouncementsDirectly();

    const announcementChannel = subscribeToAnnouncements((payload) => {
      const newAnnouncement = payload.new;
      setAnnouncementTitle(newAnnouncement.title || ""); // Set title or empty if null

      if (isValidUrl(newAnnouncement.message)) {
        setLoadingJson(true);
        fetch(newAnnouncement.message)
          .then((response) => response.json())
          .then((data) => setJsonContent(data.content)) // Assuming 'content' key exists in JSON file
          .catch((error) => {
            console.error('Error fetching JSON content:', error);
            setJsonContent('Failed to load content.');
          })
          .finally(() => setLoadingJson(false));
      } else {
        setJsonContent(newAnnouncement.message); // If not a URL, directly use the message content
      }
    });

    // Cleanup subscription on component unmount
    return () => {
      if (announcementChannel) {
        supabase.removeChannel(announcementChannel);
      }
    };
  }, []); // Only run once on mount

  return {
    jsonContent,
    announcementTitle,
    loadingJson,
    error,
  };
};

export default useAnnouncements;
