import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../services'; // Updated imports using index.js in services folder
import { useAuth } from '../context'; // Updated imports using index.js in services folder

import { renderStars, timeAgo } from '../utils'; // Updated imports using index.js in utils folder
import './HomePage.css'; // Import remains unchanged

export function Bookmarks() {
  const { user } = useAuth(); // Get the authenticated user
  const [bookmarkedNovels, setBookmarkedNovels] = useState([]); // State to store bookmarked novels
  const [latestUpdates, setLatestUpdates] = useState([]); // State to store the latest updates for the novels
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Helper function to get the correct noun for the number of novels
  const getNovelNoun = useCallback((count) => (count === 1 ? 'novel' : 'novels'), []);

  // Fetch bookmarked novels data from Supabase
  const fetchBookmarkedNovels = useCallback(async () => {
    if (!user) {
      setError('You need to be logged in to view bookmarks.');
      setLoading(false);
      return;
    }

    setLoading(true); // Start loading
    console.log("Fetching bookmarks for user:", user.id); // Debugging log

    try {
      // Fetch bookmarked novels from Supabase
      const { data: bookmarksData, error: bookmarksError } = await supabase
        .from('bookmarks')
        .select(`
          novel_id,
          novels (
            id,
            title,
            imageURL,
            AverageRating,
            totalViews,
            tags,
            chapters (id)
          )
        `)
        .eq('user_id', user.id); // Filter by the logged-in user's ID

      // Handle errors
      if (bookmarksError) throw bookmarksError;

      // Process the fetched novels
      const processedNovels = bookmarksData.map((bookmark) => ({
        ...bookmark.novels,
        totalChapters: bookmark.novels.chapters ? bookmark.novels.chapters.length : 0,
      }));

      setBookmarkedNovels(processedNovels); // Update state with processed novels

      // Fetch the latest updates
      const { data: updatesData, error: updatesError } = await supabase
        .from('chapters')
        .select(`
          id,
          novelId,
          number,
          title,
          uploadedDate,
          novels (id, title, imageURL)
        `)
        .order('uploadedDate', { ascending: false })
        .limit(15); // Limit to 15 latest updates across all novels

      // Handle errors
      if (updatesError) throw updatesError;

      // Process updates data and group by novel
      if (processedNovels.length > 0) {
        const novelIds = processedNovels.map((novel) => novel.id);
        const groupedUpdates = updatesData.reduce((acc, update) => {
          if (novelIds.includes(update.novelId)) {
            if (!acc[update.novelId]) {
              acc[update.novelId] = {
                ...update.novels,
                chapters: [],
              };
            }
            acc[update.novelId].chapters.push(update);
          }
          return acc;
        }, {});

        setLatestUpdates(Object.values(groupedUpdates)); // Update state with grouped updates
      }

      setLoading(false); // Stop loading
    } catch (err) {
      console.error('Failed to fetch data:', err); // Log any errors
      setError('Failed to fetch data. Please try again later.'); // Set error message
      setLoading(false); // Stop loading
    }
  }, [user]);

  // useEffect to fetch bookmarked novels when the component mounts or the fetch function changes
  useEffect(() => {
    // Avoid re-fetching if data is already available
    if (!bookmarkedNovels.length && user) {
      fetchBookmarkedNovels(); // Fetch the data only if needed
    }
  }, [fetchBookmarkedNovels, bookmarkedNovels.length, user]); // Dependency array includes fetch function

  // Memoize the updates and novels to avoid unnecessary re-renders
  const memoizedLatestUpdates = useMemo(() => latestUpdates, [latestUpdates]);
  const memoizedBookmarkedNovels = useMemo(() => bookmarkedNovels, [bookmarkedNovels]);

  // Render loading or error messages if necessary
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="home-page">
      <h2 className="section-header">Bookmarks</h2>

      {/* Recently Updated Novels Section */}
      <section className="latest-updates">
        <h2 className="section-header">Recently Updated</h2>
        <div className="updates-grid">
          {memoizedLatestUpdates.length > 0 ? (
            memoizedLatestUpdates.map((novel) => (
              <div key={novel.id} className="update-card">
                <Link to={`/novel/${novel.id}`} className="book-cover">
                  <div 
                    className="book-image" 
                    style={{
                      backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none', 
                      backgroundColor: 'var(--light-peach)'
                    }}>
                  </div>
                </Link>
                <div className="update-info">
                  <h3>{novel.title}</h3>
                  {novel.chapters && novel.chapters.slice(0, 4).map((chapter) => (

                    <Link
                      key={chapter.id}
                      to={`/novel/${novel.id}/chapter/${chapter.number}`}
                      className="chapter-link"
                    >
                      <span className="chapter-number">Chapter {chapter.number}</span>
                      <span className="upload-time">{timeAgo(chapter.uploadedDate)}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No updates available for your bookmarked novels.</p>
          )}
        </div>
      </section>

      {/* All Bookmarked Novels Section */}
      <section className="novels">
        <h2 className="section-header">
          You are following {memoizedBookmarkedNovels.length} {getNovelNoun(memoizedBookmarkedNovels.length)}:
        </h2>
        <div className="novels-grid">
          {memoizedBookmarkedNovels.length > 0 ? (
            memoizedBookmarkedNovels.map((novel) => (
              <div key={novel.id} className="novel-card">
                <Link to={`/novel/${novel.id}`} className="book-cover">
                  <div 
                    className="book-image" 
                    style={{
                      backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none', 
                    }}>
                  </div>
                </Link>
                <div className="novel-info">
                  <h3>
                    <Link to={`/novel/${novel.id}`} className="book-title-link">
                      {novel.title}
                    </Link>
                  </h3>
                  <div className="rating-container">
                    {renderStars(novel.AverageRating)} {/* Display average rating */}
                  </div>
                  <p>{novel.totalViews || 0} Views</p> {/* Display total views */}
                  <p>{novel.totalChapters || 0} Chapters</p> {/* Display total chapters */}
                  <div className="tags">
                    {Array.isArray(novel.tags) && novel.tags.map((tag, index) => (
                      <Link key={index} to={`/genre/${tag.toLowerCase().trim()}`} className="tag-item">
                        {tag.trim()}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>You have not bookmarked any novels yet.</p>
          )}
        </div>
      </section>
    </div>
  );
}

export default React.memo(Bookmarks);
