import React, { useContext } from 'react';
import { AdminContext } from '../context';
import { ChapterUploader } from '../components'; // Import ChapterUploader
import './AdminPage.css';


const AdminPage = () => {
  const { isFetching } = useContext(AdminContext);

  if (isFetching) return <p>Loading...</p>;

  return (
    <div className="admin-page">
      <h2>Admin Dashboard</h2>

      {/* Include other admin components or functionalities here */}
      
      {/* Batch Upload Chapters Section */}
      <ChapterUploader /> {/* Render ChapterUploader component */}
    </div>
  );
};

export default AdminPage;
