import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../services'; // Updated import using index.js in services folder
import { renderStars } from '../utils'; // Updated import using index.js in utils folder


import './TagResults.css';


import { subscribeToTagNovels } from '../services/realTimeSubscriptions'; // Import real-time subscriptions

export function TagResults() {
  const { tag } = useParams();  // Get the tag from the URL parameters
  const [novels, setNovels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNovelsByTag = async () => {
      setLoading(true);  // Start loading
      setError(null);    // Reset error state before fetching
      try {
        // Fetch all novels and filter them in JavaScript
        const { data: novelsData, error } = await supabase
          .from('novels')
          .select('*');
    
        if (error) throw error;  // Handle fetch errors
    
        // Filter novels where tags array contains the tag, case insensitive and trimmed
        const filteredNovels = novelsData.filter((novel) =>
          Array.isArray(novel.tags) &&
          novel.tags.some((t) => t.toLowerCase().trim() === tag.toLowerCase().trim())
        );
    
        setNovels(filteredNovels);  // Update state with filtered data
      } catch (err) {
        console.error('Failed to fetch novels:', err);
        setError('Failed to fetch novels. Please try again later.');
      } finally {
        setLoading(false);  // Stop loading
      }
    };
    
  
    fetchNovelsByTag();  // Fetch novels when component mounts or when the tag changes
  
    // Subscribe to real-time updates for novels by tag
    const tagChannel = subscribeToTagNovels(tag, (updatedNovels) => {
      setNovels((prevNovels) => {
        // Update the existing novels with the new real-time data
        return updatedNovels;
      });
    });
  
    // Cleanup on unmount
    return () => {
      tagChannel.unsubscribe();
    };
  
  }, [tag]);  // Dependency on `tag` ensures fetch on tag change
  

  if (loading) return <p>Loading...</p>;  // Show loading state
  if (error) return <p className="error-message">{error}</p>;  // Show error message if fetch fails

  const resultsText = novels.length === 1 ? 'result' : 'results';

  return (
    <div className="tag-results-page">
      <h1 className="section-header">
        {novels.length} {resultsText} found for "{tag}"
      </h1>
      <div className="novels-grid">
        {novels.length > 0 ? (
          novels.map((novel) => (
            <div key={novel.id} className="novel-card">
              <div className="book-cover">
                <Link to={`/novel/${novel.id}`}>
                  <img
                    src={novel.imageURL}
                    alt={novel.title}
                    className="book-image"
                    onError={(e) => e.target.src = 'path/to/default-image.png'}  // Fallback image for broken URLs
                  />
                </Link>
              </div>
              <div className="novel-info">
                <h3>
                  <Link to={`/novel/${novel.id}`} className="book-title-link">
                    {novel.title}
                  </Link>
                </h3>
                <div className="rating-container">
                  {renderStars(novel.AverageRating)}
                </div>
                <p>Author: {novel.author}</p>
                <p>Chapters: {novel.totalChapters}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No novels found for the tag "{tag}".</p>
        )}
      </div>
    </div>
  );
}

export default TagResults;
