import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';  
import { useAccount } from '../context/AccountContext';  
import { useModal } from '../context/ModalContext';  
import './CommentSection.css';

const CommentSection = () => {
  const { id, chapterNumber } = useParams();
  const { user } = useAuth();
  const { profile } = useAccount();
  const { openLoginModal } = useModal();
  const [ssoToken, setSsoToken] = useState(null);
  const commentsContainerRef = useRef(null);
  const initializedRef = useRef(false);

  // Fetch the SSO token when both user and profile are available
  useEffect(() => {
    const fetchSsoToken = async () => {
      if (user && profile) {
        try {
          const response = await fetch('/api/hyvor-talk-sso', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user, profile }),
          });

          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

          const data = await response.json();
          setSsoToken(data);

          // Store the SSO token in localStorage for later use
          localStorage.setItem('hyvorSsoToken', JSON.stringify(data));
        } catch (err) {
          console.error('Error fetching SSO token:', err);
        }
      }
    };

    if (user && profile) {
      fetchSsoToken();
    }
  }, [user, profile]);

  // Log SSO token for debugging purposes (optional)
  useEffect(() => {
    if (ssoToken) {
      console.log('Fetched SSO Token:', ssoToken);
    }
  }, [ssoToken]);

  // Initialize Hyvor Talk with lazy loading
  useEffect(() => {
    const commentsContainer = commentsContainerRef.current;
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !initializedRef.current) {
          // Initialize the Hyvor Talk only when the comment section is in view
          const comments = document.createElement('hyvor-talk-comments');
          comments.setAttribute('website-id', '11927');
          comments.setAttribute('loading', 'manual'); // Manual loading
          const pageId = chapterNumber ? `novel-${id}-chapter-${chapterNumber}` : `novel-${id}`;
          comments.setAttribute('page-id', pageId);
  
          if (ssoToken) {
            comments.setAttribute('sso-user', ssoToken.user);
            comments.setAttribute('sso-hash', ssoToken.hash);
          }
  
          commentsContainer.appendChild(comments);
          comments.load(); // Manually trigger loading
          initializedRef.current = true;
          observer.disconnect(); // Stop observing once loaded
        }
      },
      { rootMargin: '100px' }
    );
  
    if (commentsContainer) {
      observer.observe(commentsContainer);
    }
  
    return () => {
      observer.disconnect();
    };
  }, [id, chapterNumber, ssoToken, openLoginModal]);
  
  

  return (
    <div className="comment-section">
      <div ref={commentsContainerRef}></div>
    </div>
  );
};

export default CommentSection;
