// src/components/ModalManager.js
import React from 'react';
import { LoginPage, SignUpPage } from '../pages'; // Import pages here
import { useModal } from '../context'; // Import the modal context hook

const ModalManager = () => {
  const {
    isLoginModalOpen,
    closeLoginModal,
    openSignUpModal,
    isSignUpModalOpen,
    closeSignUpModal,
    openLoginModal,
    loginModalMessage,
  } = useModal();

  const handleModalBackgroundClick = (event, closeModal) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      {/* Render Login Modal */}
      {isLoginModalOpen && (
        <div className="modal" onClick={(e) => handleModalBackgroundClick(e, closeLoginModal)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <LoginPage onClose={closeLoginModal} openSignUpModal={openSignUpModal} contextMessage={loginModalMessage} />
          </div>
        </div>
      )}

      {/* Render Sign Up Modal */}
      {isSignUpModalOpen && (
        <div className="modal" onClick={(e) => handleModalBackgroundClick(e, closeSignUpModal)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SignUpPage onClose={closeSignUpModal} openLoginModal={openLoginModal} contextMessage={loginModalMessage} />
          </div>
        </div>
      )}
    </>
  );
};

export default ModalManager;
